<template>
  <section class="payment-diary" @click.stop="pickerclick">
    <div class="top-bar bg-white" style="justify-content: left">
      <el-date-picker ref="daterangeRef" @change="pickerclickitr" v-model="paidDate" type="daterange"
                      value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                      range-separator="~" start-placeholder=" 交易开始日期" end-placeholder="结束日期"
                      size="small" style="width: 250px;margin-right: 10px"/>
      <el-date-picker ref="payBillDateRef" @change="pickerclickitr" v-model="payBillDate" type="daterange"
                      value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                      range-separator="~" start-placeholder="  账单开始日期" end-placeholder="结束日期"
                      size="small" style="width: 250px;margin-right: 10px"/>
      <el-input size="small" style="width: 260px;margin-right: 10px;" v-model="formSearch.keyword"
                placeholder="住户名称、电话、房间、 备注、流水号"/>
      <el-select ref="payBillTypesRef" v-model="payBillTypes" placeholder="请选择项目名称" multiple filterable
                 collapse-tags clearable
                 style="width: 200px;margin-right: 10px" size="small">
        <el-option v-for="item in billTypes" :key="item" :label="item" :value="item"/>
      </el-select>
      <el-select ref="payTypesRef" v-model="payTypes" placeholder="请选择收款方式" multiple filterable collapse-tags
                 clearable
                 style="width: 200px;margin-right: 10px" size="small">
        <el-option v-for="item in natureCodeList" :key="item" :label="item" :value="item"/>
      </el-select>
      <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
      <el-button @click="handleReset" size="small">重置</el-button>
      <el-button type="primary" size="small" @click="handleExport">导出数据</el-button>
    </div>
    <div class="bg-white">
      <r-e-table class="bg-white" ref="tableRef" :dataRequest="getProceedsListApi" :columns="tableColumn"
                 :query="formSearch" :height="790" show-summary :summary-method="getSummaries">
        <template slot="empty">
          <el-empty/>
        </template>
      </r-e-table>
    </div>
  </section>
</template>

<script>
import {numberFormat} from "@custom";
import {tableColumn} from "./data";
import {getProceedsListApi, proceedsExport, getProceedsPayTotalApi, getPayBillTypeNameApi} from "@/api/payment-diary";
import {downloadByData} from "@/utils/hooks/download";
import {timeFormat} from "@custom/index";

export default {
  name: "payment-diary",
  data() {
    return {
      itr: true,
      tableColumn: tableColumn(this),
      formSearch: {
        paidStartDate: null,
        paidEndDate: null,
        payBillStartDate: null,
        payBillEndDate: null,
        keyword: null,
        payBillTypes: null,
        payTypes: null,
      },
      natureCodeList: ["余额抵扣", "余额抵扣(杂费)", "余额抵扣(房租)", "刷卡", "微信小程序", "押金剩余退还", "押金抵扣", "现金", "转账"],
      // billTypes: ["其他费用", "定金", "房租", "押金", "服务费", "杂费", "水费", "清洁费", "电费", "签约费用", "线上付款", "充值", "家具损坏费", "换房手续费", "房租充值", "杂费充值 "],
      billTypes: [], // 项目名称
      paidDate: [],
      payBillDate: [],
      payBillTypes: [],
      payTypes: [],
      loadingOptions: {
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      },
      payAmount: 0,
      payBillAmount: 0,
      receivableAmount: 0,
      total: 0,
    };
  },
  methods: {
    getProceedsListApi(params) {
      return getProceedsListApi(params);
    },
    handleSearch() {
      this.$refs["tableRef"].getTableData();
      this.getProceedsPayTotal();
    },
    handleReset() {
      this.formSearch = {
        paidStartDate: null,
        paidEndDate: null,
        payBillStartDate: null,
        payBillEndDate: null,
        keyword: null,
        payBillTypes: null,
        payTypes: null,
      };
      this.paidDate = [];
      this.payBillDate = [];
      this.payBillTypes = [];
      this.payTypes = [];
      this.handleSearch();
    },
    handleExport() {
      const formSearch = this.formSearch;
      const loadingOptions = this.loadingOptions;
      const loading = this.$loading({...loadingOptions});
      proceedsExport({...formSearch}).then(res => {
        downloadByData({data: res, filename: `收付日记${timeFormat(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.xls`});
        loading.close();
      }).catch(err => loading.close());
    },
    getSummaries(param) {
      const {columns, data} = param;
      const sums = [];
      const payAmount = this.payAmount;
      const payBillAmount = this.payBillAmount;
      const receivableAmount = this.receivableAmount;
      const total = this.total;
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (index === 5 || index === 8 || index === 9 || index === 10) {
          sums[5] = numberFormat(payBillAmount / 100);
          sums[8] = numberFormat(receivableAmount / 100);
          sums[9] = numberFormat(payAmount / 100);
          sums[10] = numberFormat(total / 100);
        } else sums[index] = '';
      });
      return sums;
    },
    async getProceedsPayTotal() {
      const formSearch = this.formSearch;
      let res = await getProceedsPayTotalApi({...formSearch});
      const {info: {payAmount, payBillAmount, receivableAmount, total}} = res;
      this.payAmount = payAmount;
      this.payBillAmount = payBillAmount;
      this.receivableAmount = receivableAmount;
      this.total = total;
    },
    // 2024/01/08 张晓瑜新增项目名称下拉框
    async getPayBillTypeName() {
      const {list} = await getPayBillTypeNameApi();
      this.billTypes = list;
    },
    // 2024-4-18-新增点击空白关闭组件
    pickerclick(e) {
      console.log(this.itr)
      this.itr = !this.itr;
      if (this.itr) {
        this.$refs['daterangeRef'].hidePicker();
        this.$refs['payBillDateRef'].hidePicker();
      }
      this.$refs.payBillTypesRef.blur()
      this.$refs.payTypesRef.blur()
    },
    pickerclickitr() {
      this.itr = true;
      console.log(this.itr)
    },
  },
  deactivated(){
    // alert('离开')
    // 2024-7-10-陈守亮处理切换页面关闭组件
    this.pickerclick()
  },
  watch: {
    paidDate(value) {
      if (value && value.length === 2) {
        let [paidStartDate, paidEndDate] = value;
        this.formSearch.paidStartDate = paidStartDate;
        this.formSearch.paidEndDate = paidEndDate;
      } else {
        this.formSearch.paidStartDate = null;
        this.formSearch.paidEndDate = null;
      }
    },
    payBillDate(value) {
      if (value && value.length === 2) {
        let [payBillStartDate, payBillEndDate] = value;
        this.formSearch.payBillStartDate = payBillStartDate;
        this.formSearch.payBillEndDate = payBillEndDate;
      } else {
        this.formSearch.payBillStartDate = null;
        this.formSearch.payBillEndDate = null;
      }
    },
    payBillTypes(value) {
      if (value && value.length !== 0) this.formSearch.payBillTypes = value.join(";");
      else this.formSearch.payBillTypes = null;
    },
    payTypes(value) {
      if (value && value.length !== 0) this.formSearch.payTypes = value.join(";");
      else this.formSearch.payTypes = null;
    },
  },
  async mounted() {
    this.getProceedsPayTotal();
    this.getPayBillTypeName()
  },
}
</script>

<style lang="scss" scoped>
.payment-diary {
  padding: VH(15px) VW(15px);

  .top-bar {
    display: flex;
    margin: VH(10px) 0 VH(25px);
    padding: VH(15px) VW(15px);
    justify-content: center;

    .el-radio-button.is-active {
      box-shadow: 0 0 10px #DDD inset;
    }
  }
}
</style>
